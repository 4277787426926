@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  background: #141e30;
  letter-spacing: 0.3px;
  background: linear-gradient(180deg, rgba(0, 22, 41, 1) 0%, rgba(153, 209, 255, 1) 100%);
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7vh;
}
/* Sider */
.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7vh;
}

/* Content space */
.content {
  padding: 24px;
  min-height: 360px;
}

/* Barra ricerca clienti, progetti */
.searchByName {
  width: 200px;
  position: relative;
}

/* Pulsanti nuovoCliente, nuovoProgetto */
.newItem {
  float: right;
}

/* Commesse */
.pulsantiDiv {
  margin-bottom: 3vh;
  display: flex;
  justify-content: space-between;
}
.titleTabTot {
  display: flex;
  align-items: center;
  margin: 0;
}

/* Grafici Commesse */
.graficoDiv {
  border: none;
  padding: 10px 20px;
  border-radius: 1vh;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
}

.graf {
  padding: 8px;
}

.treGraficiDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 35vh;
}
.garficoH4 {
  margin: 0;
}

/* Form */

.formsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vh;
}

.form {
  border: none;
  padding: 2vh;
  border-radius: 2vh;
  width: 70%;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
}
.formComm {
  border: none;
  padding: 2vh;
  border-radius: 2vh;
  width: 95%;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
}

.inputsLength {
  width: 250px;
}

.noteItem {
  resize: vertical;
  height: 150px;
  width: 250px;
}

.textareaNewComm {
  resize: vertical;
  height: 125px;
  width: 250px;
}

@media screen and (max-width: 800px) {
  .form {
    height: 80vh;
    width: 96%;
  }
  .noteLabel {
    position: absolute;
    top: 32vh;
    left: -16vh;
  }
  .noteItem {
    position: absolute;
    top: 35vh;
    left: -16vh;
  }

  .btnsAdd {
    position: absolute;
    top: 52vh;
    left: -16vh;
    width: 200px;
  }
}

/* button creazione cliente e progetto */
.btnsAdd {
  width: 250px;
  height: 5vh;
}

/* Filter component */
.searchFilterDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
}

@media screen and (min-width: 530px) {
  .filterOrdersSelect {
    width: 300px;
  }
  .filterPerformancesSelect {
    width: 300px;
  }
}

@media screen and (min-width: 997px) {
  .filterOrdersSelect {
    width: 600px;
  }
  .filterPerformancesSelect {
    width: 600px;
  }
}

@media screen and (min-width: 1400px) {
  .filterOrdersSelect {
    width: 1000px;
  }
}

.selectDevelopState {
  width: 15vh;
}

/* BTNS */
.primaryBtn {
  background-color: #002d52;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 2.5vh 1vh 2.5vh 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.primaryBtn:hover {
  background-color: #001629;
  color: white;
  border: none;
  transform: scale(0.99);
}

.primaryBtn:focus {
  background-color: #002d52;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 2vh 1vh 2vh 1vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnIndietro {
  position: absolute;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.btnIndietro:hover {
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.btnIndietro:active {
  transform: scale(0.9);
}

/* Legenda */
.legenda {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.legendaDivs {
  display: flex;
  align-items: center;
}

.circleEl {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 4px;
}

td.ant-table-cell {
  font-weight: 300;
}

.detailsDiv {
  border: none;
  padding: 2vh;
  border-radius: 2vh;
  width: 90%;
  background: #ffffff;
  box-shadow: 12px 12px 24px #d9d9d9, -12px -12px 24px #ffffff;
}

.table-red-bad {
  background-color: #ffc2c2;
}
.table-red {
  background-color: #ffebeb;
}
.table-yellow {
  background-color: #fffed6;
}
.table-green {
  background-color: #c2ffd2;
}

.bluClass {
  background-color: #0065e0;
}

@keyframes aggiorna {
  0.0% {
  }
  19.7% {
    transform: rotate(90deg);
  }
  30.0% {
    transform: rotate(135deg);
  }
  39.5% {
    transform: rotate(180deg);
  }
  50.3% {
    transform: rotate(225deg);
  }
  59.9% {
    transform: rotate(270deg);
  }
  70.4% {
    transform: rotate(315deg);
  }
  80.3% {
    transform: rotate(360deg);
  }
  9.8% {
    transform: rotate(45deg);
  }
  90.2% {
    transform: rotate(360deg);
  }
}

.animate {
  animation: aggiorna 2s linear 0s 1 normal none;
}

.hidden {
  display: none;
}

.amplify-button--primary {
  background-color: #141e30 !important;
  color: white !important;
  border-radius: 5px !important;
}

.amplify-button--primary {
  background-color: #002d52 !important;
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 2vh 1vh 2vh 1vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.amplify-button--primary:hover {
  background-color: #001629 !important;
  color: white !important;
  border: none !important;
}

.amplify-button--primary:focus {
  background-color: #002d52 !important;
  color: white !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 2vh 1vh 2vh 1vh !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.btnSavePerf {
  margin-top: 3vh;
  width: 20%;
  height: 5vh;
}

.upperCaseText {
  text-transform: uppercase;
}

.flexDisplay {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.editingClass {
  /* background-color: #fffcc2 !important; */
  background-color: #fffdd6;
}
